import PersonIcon from './PersonIcon';
import BusinessIcon from './BusinessIcon';
import TeamIcon from './TeamIcon';
import PaymentIcon from './PaymentIcon';
import NotificationIcon from './NotificationIcon';
import EditIcon from './EditIcon';
import DocumentIcon from './DocumentIcon';
import ToolIcon from './ToolIcon';
import AddressIcon from './AddressIcon';
import MapIcon from './MapIcon';
import LinkedInIcon from './LinkedInIcon';
import FacilityIcon from './FacilityIcon';
import DeviceIcon from './DeviceIcon';
import ContractIcon from './ContractIcon';
import ApprovalIcon from './ApprovalIcon';
import EmailIcon from './EmailIcon';
import SendingEmail from './SendingEmail';
import FailedEmail from './FailedEmail';
import SuccessEmail from './SuccessEmail';
import CooperationIcon from './CooperationIcon';
import ErrorIcon from './ErrorIcon';
import ServiceIcon from './ServiceIcon';
import CertifiedIcon from './CertifiedIcon';
import NoResultsIcon from './NoResultsIcon';

export {
  PersonIcon,
  BusinessIcon,
  TeamIcon,
  PaymentIcon,
  NotificationIcon,
  EditIcon,
  DocumentIcon,
  ToolIcon,
  MapIcon,
  AddressIcon,
  LinkedInIcon,
  FacilityIcon,
  DeviceIcon,
  ContractIcon,
  ApprovalIcon,
  EmailIcon,
  SendingEmail,
  FailedEmail,
  SuccessEmail,
  CooperationIcon,
  ErrorIcon,
  ServiceIcon,
  CertifiedIcon,
  NoResultsIcon,
};
